import React from 'react'
import { DateTime } from 'luxon'
import online from '../../../images/icons/online.svg'
import offline from '../../../images/icons/offline.svg'

interface SiteStatusIconProps {
	site_IsAliveTs: string | null
}

export const SiteStatusIcon = (props: SiteStatusIconProps) => {
	/* Site is considered online if we have a keep-alive reading within the last 5 minutes */
	const calculateSiteOnlineStatus = (site_IsAliveTs: string | null) => {
		if (!site_IsAliveTs) return false

		const now = DateTime.utc()
		const diff = now.diff(DateTime.fromISO(site_IsAliveTs, { zone: 'utc', setZone: true }), 'minutes').minutes
		return diff < 5
	}

	return <>{calculateSiteOnlineStatus(props.site_IsAliveTs) ? <img src={online} alt={'Site is online'} /> : <img src={offline} alt={'Site is offline'} />}</>
}
