import Papa from 'papaparse'

import { recordStatus } from '../../../constants/recordStatus'
import { assetType as assetTypes, AssetType, AssetTypeNameValues } from '../../../constants/assetType'
import { AssetTreeStatusId, assetTreeStatus } from '../../../constants/assetTreeStatus'

import { Asset, AssetResult, defaultAsset } from '../../../models/Asset'
import { AssetMake, AssetMakeResult, defaultAssetMake } from '../../../models/AssetMake'
import { AssetModel, AssetModelResult, defaultAssetModel } from '../../../models/AssetModel'
import { defaultLocationBuilding, LocationBuilding, LocationBuildingResult } from '../../../models/LocationBuilding'
import { defaultLocationFloor, LocationFloor, LocationFloorResult } from '../../../models/LocationFloor'
import { defaultLocationArea, LocationArea, LocationAreaResult } from '../../../models/LocationArea'
import { defaultLocationRoom, LocationRoom, LocationRoomResult } from '../../../models/LocationRoom'
import { AssetTreeResult, defaultAssetTree } from '../../../models/AssetTree'
import { ChannelMapResult, defaultChannelMap } from '../../../models/ChannelMap'
import { defaultCreated, defaultModified } from '../../../models/History'
import * as Types from './assetImport'
import { AppState } from '../../../App.d'

import * as Request from '../../../utilities/request'
import { readingType } from '../../../constants/readingType'
import { formatOutgoingDateTime } from '../../../utilities/formatDate'
import { AxiosError } from 'axios'
import { MSExceptionResult } from '../../../utilities/request.d'
import { PaymentAgreement } from '../../../models/PaymentAgreement'
import { SF_SSF_Mapping } from '../../../models/SF_SSF_Mapping'

interface ImportParams {
	appState: AppState
	auxData: Types.AuxiliaryData
	updateFeedback: (feedback: Types.ImportUserFeedback) => void
}

export const importHandler = async <T,>(
	importParams: ImportParams,
	fileData: Types.FileData,
	fileImportHandler: (importParams: ImportParams, fileData: T[]) => Promise<Types.ImportUserFeedback>
) => {
	if (!fileData.file) {
		return { inProgress: false, totalCount: 0, totalComplete: 0, errors: [] }
	}

	const jsonFile: T[] = await new Promise<T[]>((resolve, reject) => {
		try {
			Papa.parse(fileData.file as File, {
				header: true,
				dynamicTyping: true,
				skipEmptyLines: true,
				complete: async (results) => {
					resolve(results.data as T[])
				},
			})
		} catch (e) {
			console.log('CSV parsing error', e)
			reject(e)
		}
	})

	return fileImportHandler(importParams, jsonFile)
}

const createSFtoSSFmapping = async (appState: AppState, SF_Table: string, SF_Id: string, SF_Site_Id: string, SSF_Id: string, SSF_Site_Id: string) => {
	const mapping: Partial<SF_SSF_Mapping> = {
		SF_Table: SF_Table,
		SF_Id: SF_Id,
		SF_Site_Id: SF_Site_Id,
		SSF_Id: SSF_Id,
		SSF_Site_Id: SSF_Site_Id,
	}
	await Request.post<string>(`SF_SSF_Mapping`, mapping, appState.authState)
}

export const importFixtures = async (importParams: ImportParams, jsonFile: Types.FixtureImportFileRow[]) => {
	const { appState, auxData, updateFeedback } = importParams
	const feedback: Types.ImportUserFeedback = { inProgress: false, totalCount: 0, totalComplete: 0, errors: [] }

	// Loop over the file generating assets and surrounding fields as we go
	if (jsonFile.length > 0) {
		feedback.inProgress = true
		feedback.totalCount = jsonFile.length
		updateFeedback(feedback)

		for (let i = 0; i < jsonFile.length; i++) {
			try {
				validateRow(jsonFile[i], appState)

				const assetTypeName = AssetTypeNameValues.find((name) => name === jsonFile[i].Type)
				if (!assetTypeName) {
					throw `Asset Type '${jsonFile[i].Type}' is not supported.`
				}
				const assetType = assetTypes[assetTypeName]

				const assetMake = await handleAssetMakeImport(appState, jsonFile[i].Make, auxData.assetMakes)
				const assetModel = await handleAssetModelImport(appState, jsonFile[i].Model, auxData.assetModels, assetMake, assetType)
				const locationBuilding = await handleLocationBuildingImport(appState, jsonFile[i].Building, auxData.locationBuildings)
				const locationFloor = await handleLocationFloorImport(appState, jsonFile[i].Floor, auxData.locationFloors, locationBuilding)
				const locationArea = await handleLocationAreaImport(appState, jsonFile[i].Area, auxData.locationAreas, locationFloor)
				const locationRoom = await handleLocationRoomImport(appState, jsonFile[i].Room, auxData.locationRooms, locationArea)

				const asset: Partial<Asset> = defaultAsset({
					asset_Name: jsonFile[i].Name,
					asset_SerialNo: jsonFile[i].SerialNumber,
					asset_FacilityAssetNumber: jsonFile[i].FacilityNumber,
					asset_InstallDate: jsonFile[i].InstallDate !== '' ? jsonFile[i].InstallDate : null,
					asset_LocationNote: jsonFile[i].LocationNote,
					site_Id: appState.currentSite?.site_Id,
					assetType_Id: assetType.id,
					assetMake_Id: assetMake ? assetMake.assetMake_Id : null,
					assetModel_Id: assetModel ? assetModel.assetModel_Id : null,
					locationBuilding_Id: locationBuilding ? locationBuilding.locationBuilding_Id : null,
					locationFloor_Id: locationFloor ? locationFloor.locationFloor_Id : null,
					locationArea_Id: locationArea ? locationArea.locationArea_Id : null,
					locationRoom_Id: locationRoom ? locationRoom.locationRoom_Id : null,
					created: defaultCreated({ create_UserId: appState.userAttributes.user_Id }),
					modified: defaultModified({ modified_UserId: appState.userAttributes.user_Id }),
					recordStatus_Id: recordStatus.Active.id,
				})
				const createdAssetResponse = await Request.post<AssetResult>(`asset`, asset, appState.authState)
				const createdAsset = createdAssetResponse.data.assets[0]

				if (createdAsset) {
					auxData.assets.push(createdAsset)
				} else {
					throw createdAssetResponse.data.errors
				}
			} catch (error) {
				feedback.errors.push(`Unable to process row ${JSON.stringify(jsonFile[i])}: "${getErrorMessage(error)}"`)
			} finally {
				feedback.totalComplete = i + 1
				updateFeedback(feedback)
			}
		}
	}

	return feedback
}

export const importTmvs = async (importParams: ImportParams, jsonFile: Types.TmvImportFileRow[]) => {
	const { appState, auxData, updateFeedback } = importParams
	const feedback: Types.ImportUserFeedback = { inProgress: false, totalCount: 0, totalComplete: 0, errors: [] }

	// Loop over the file generating assets and surrounding fields as we go
	if (jsonFile.length > 0) {
		feedback.inProgress = true
		feedback.totalCount = jsonFile.length
		updateFeedback(feedback)

		for (let i = 0; i < jsonFile.length; i++) {
			try {
				validateRow(jsonFile[i], appState)

				const assetType = assetTypes.TMV

				const assetMake = await handleAssetMakeImport(appState, jsonFile[i].Make, auxData.assetMakes)
				const assetModel = await handleAssetModelImport(appState, jsonFile[i].Model, auxData.assetModels, assetMake, assetType)
				const locationBuilding = await handleLocationBuildingImport(appState, jsonFile[i].Building, auxData.locationBuildings)
				const locationFloor = await handleLocationFloorImport(appState, jsonFile[i].Floor, auxData.locationFloors, locationBuilding)
				const locationArea = await handleLocationAreaImport(appState, jsonFile[i].Area, auxData.locationAreas, locationFloor)
				const locationRoom = await handleLocationRoomImport(appState, jsonFile[i].Room, auxData.locationRooms, locationArea)
				const paymentAgreement = await handlePaymentAgreementImport(jsonFile[i].PaymentAgreement, auxData.paymentAgreements)

				const asset: Partial<Asset> = defaultAsset({
					asset_Name: jsonFile[i].Name,
					asset_SerialNo: jsonFile[i].SerialNumber,
					asset_FacilityAssetNumber: jsonFile[i].FacilityNumber,
					asset_RoomsServiced: jsonFile[i].RoomsServiced,
					asset_InstallDate: jsonFile[i].InstallDate !== '' ? jsonFile[i].InstallDate : null,
					asset_LocationNote: jsonFile[i].LocationNote,
					asset_BacnetPoint: jsonFile[i].SF_BacnetPoint,
					site_Id: appState.currentSite?.site_Id,
					assetType_Id: assetType.id,
					assetMake_Id: assetMake ? assetMake.assetMake_Id : null,
					assetModel_Id: assetModel ? assetModel.assetModel_Id : null,
					locationBuilding_Id: locationBuilding ? locationBuilding.locationBuilding_Id : null,
					locationFloor_Id: locationFloor ? locationFloor.locationFloor_Id : null,
					locationArea_Id: locationArea ? locationArea.locationArea_Id : null,
					locationRoom_Id: locationRoom ? locationRoom.locationRoom_Id : null,
					paymentAgreement_Id: paymentAgreement ? paymentAgreement.paymentAgreement_Id : null,
					created: defaultCreated({ create_UserId: appState.userAttributes.user_Id }),
					modified: defaultModified({ modified_UserId: appState.userAttributes.user_Id }),
					recordStatus_Id: recordStatus.Active.id,
				})
				const createdAssetResponse = await Request.post<AssetResult>(`asset`, asset, appState.authState)
				const createdAsset = createdAssetResponse.data.assets[0]

				if (createdAsset) {
					auxData.assets.push(createdAsset)
					await handleAssetTreeImport(
						appState,
						jsonFile[i].AssetsServiced !== null ? jsonFile[i].AssetsServiced.split(';') : [],
						auxData.assets,
						createdAsset.asset_Id,
						assetTreeStatus.Service.id
					)

					if (jsonFile[i].SF_Id && jsonFile[i].SF_Site_Id) {
						await createSFtoSSFmapping(appState, 'Tmvs', jsonFile[i].SF_Id, jsonFile[i].SF_Site_Id, createdAsset.asset_Id, createdAsset.site_Id)
					}
				} else {
					throw createdAssetResponse.data.errors
				}
			} catch (error) {
				feedback.errors.push(`Unable to process row ${JSON.stringify(jsonFile[i])}: "${getErrorMessage(error)}"`)
			} finally {
				feedback.totalComplete = i + 1
				updateFeedback(feedback)
			}
		}
	}

	return feedback
}

export const importSfmStandardHub = async (importParams: ImportParams, jsonFile: Types.SfmStandardHubImportFileRow[]) => {
	const { appState, auxData, updateFeedback } = importParams
	const feedback: Types.ImportUserFeedback = { inProgress: false, totalCount: 0, totalComplete: 0, errors: [] }

	// Loop over the file generating assets and surrounding fields as we go
	if (jsonFile.length > 0) {
		feedback.inProgress = true
		feedback.totalCount = jsonFile.length
		updateFeedback(feedback)

		for (let i = 0; i < jsonFile.length; i++) {
			try {
				validateRow(jsonFile[i], appState)

				const assetType = assetTypes['SFM Standard Hub']

				const locationBuilding = await handleLocationBuildingImport(appState, jsonFile[i].Building, auxData.locationBuildings)
				const locationFloor = await handleLocationFloorImport(appState, jsonFile[i].Floor, auxData.locationFloors, locationBuilding)
				const locationArea = await handleLocationAreaImport(appState, jsonFile[i].Area, auxData.locationAreas, locationFloor)
				const locationRoom = await handleLocationRoomImport(appState, jsonFile[i].Room, auxData.locationRooms, locationArea)

				const asset: Partial<Asset> = defaultAsset({
					asset_Name: jsonFile[i].Name,
					asset_SerialNo: jsonFile[i].SerialNumber,
					asset_FacilityAssetNumber: jsonFile[i].FacilityNumber,
					asset_InstallDate: jsonFile[i].InstallDate !== '' ? jsonFile[i].InstallDate : null,
					asset_BacnetPoint: jsonFile[i].SF_BacnetPoint,
					hub_Number: jsonFile[i].HubNumber,
					asset_LocationNote: jsonFile[i].LocationNote,
					site_Id: appState.currentSite?.site_Id,
					assetType_Id: assetType.id,
					locationBuilding_Id: locationBuilding ? locationBuilding.locationBuilding_Id : null,
					locationFloor_Id: locationFloor ? locationFloor.locationFloor_Id : null,
					locationArea_Id: locationArea ? locationArea.locationArea_Id : null,
					locationRoom_Id: locationRoom ? locationRoom.locationRoom_Id : null,
					created: defaultCreated({ create_UserId: appState.userAttributes.user_Id }),
					modified: defaultModified({ modified_UserId: appState.userAttributes.user_Id }),
					recordStatus_Id: recordStatus.Active.id,
				})
				const createdAssetResponse = await Request.post<AssetResult>(`asset`, asset, appState.authState)
				const createdAsset = createdAssetResponse.data.assets[0]

				if (createdAsset) {
					auxData.assets.push(createdAsset)
					await Promise.all([
						handleAssetTreeImport(
							appState,
							jsonFile[i].AssetsMonitored !== null ? jsonFile[i].AssetsMonitored.split(';') : [],
							auxData.assets,
							createdAsset.asset_Id,
							assetTreeStatus.Monitor.id
						),
						handleChannelMappingImport(appState, sfmStandardHubRowToChannelMap(jsonFile[i]), auxData.assets, createdAsset.asset_Id),
					])
				} else {
					throw createdAssetResponse.data.errors
				}
			} catch (error) {
				feedback.errors.push(`Unable to process row ${JSON.stringify(jsonFile[i])}: "${getErrorMessage(error)}"`)
			} finally {
				feedback.totalComplete = i + 1
				updateFeedback(feedback)
			}
		}
	}

	return feedback
}

export const importSfmTfpHub = async (importParams: ImportParams, jsonFile: Types.SfmTfpHubImportFileRow[]) => {
	const { appState, auxData, updateFeedback } = importParams
	const feedback: Types.ImportUserFeedback = { inProgress: false, totalCount: 0, totalComplete: 0, errors: [] }

	// Loop over the file generating assets and surrounding fields as we go
	if (jsonFile.length > 0) {
		feedback.inProgress = true
		feedback.totalCount = jsonFile.length
		updateFeedback(feedback)

		for (let i = 0; i < jsonFile.length; i++) {
			try {
				validateRow(jsonFile[i], appState)

				const assetType = assetTypes['SFM TFP Hub']

				const locationBuilding = await handleLocationBuildingImport(appState, jsonFile[i].Building, auxData.locationBuildings)
				const locationFloor = await handleLocationFloorImport(appState, jsonFile[i].Floor, auxData.locationFloors, locationBuilding)
				const locationArea = await handleLocationAreaImport(appState, jsonFile[i].Area, auxData.locationAreas, locationFloor)
				const locationRoom = await handleLocationRoomImport(appState, jsonFile[i].Room, auxData.locationRooms, locationArea)

				const asset: Partial<Asset> = defaultAsset({
					asset_Name: jsonFile[i].Name,
					asset_SerialNo: jsonFile[i].SerialNumber,
					asset_FacilityAssetNumber: jsonFile[i].FacilityNumber,
					asset_InstallDate: jsonFile[i].InstallDate !== '' ? jsonFile[i].InstallDate : null,
					hub_Number: jsonFile[i].HubNumber,
					asset_LocationNote: jsonFile[i].LocationNote,
					site_Id: appState.currentSite?.site_Id,
					assetType_Id: assetType.id,
					locationBuilding_Id: locationBuilding ? locationBuilding.locationBuilding_Id : null,
					locationFloor_Id: locationFloor ? locationFloor.locationFloor_Id : null,
					locationArea_Id: locationArea ? locationArea.locationArea_Id : null,
					locationRoom_Id: locationRoom ? locationRoom.locationRoom_Id : null,
					created: defaultCreated({ create_UserId: appState.userAttributes.user_Id }),
					modified: defaultModified({ modified_UserId: appState.userAttributes.user_Id }),
					recordStatus_Id: recordStatus.Active.id,
				})
				const createdAssetResponse = await Request.post<AssetResult>(`asset`, asset, appState.authState)
				const createdAsset = createdAssetResponse.data.assets[0]

				if (createdAsset) {
					auxData.assets.push(createdAsset)
					await Promise.all([
						handleAssetTreeImport(
							appState,
							jsonFile[i].AssetsMonitored !== null ? jsonFile[i].AssetsMonitored.split(';') : [],
							auxData.assets,
							createdAsset.asset_Id,
							assetTreeStatus.Monitor.id
						),
						handleChannelMappingImport(appState, sfmTfpHubRowToChannelMap(jsonFile[i]), auxData.assets, createdAsset.asset_Id),
					])
				} else {
					throw createdAssetResponse.data.errors
				}
			} catch (error) {
				feedback.errors.push(`Unable to process row ${JSON.stringify(jsonFile[i])}: "${getErrorMessage(error)}"`)
			} finally {
				feedback.totalComplete = i + 1
				updateFeedback(feedback)
			}
		}
	}

	return feedback
}

export const importRfCompactTransmitter = async (importParams: ImportParams, jsonFile: Types.RfCompactTransmitterFileRow[]) => {
	const { appState, auxData, updateFeedback } = importParams
	const feedback: Types.ImportUserFeedback = { inProgress: false, totalCount: 0, totalComplete: 0, errors: [] }

	// Loop over the file generating assets and surrounding fields as we go
	if (jsonFile.length > 0) {
		feedback.inProgress = true
		feedback.totalCount = jsonFile.length
		updateFeedback(feedback)

		for (let i = 0; i < jsonFile.length; i++) {
			try {
				validateRow(jsonFile[i], appState)

				const assetType = assetTypes['RF Compact Transmitter']

				const locationBuilding = await handleLocationBuildingImport(appState, jsonFile[i].Building, auxData.locationBuildings)
				const locationFloor = await handleLocationFloorImport(appState, jsonFile[i].Floor, auxData.locationFloors, locationBuilding)
				const locationArea = await handleLocationAreaImport(appState, jsonFile[i].Area, auxData.locationAreas, locationFloor)
				const locationRoom = await handleLocationRoomImport(appState, jsonFile[i].Room, auxData.locationRooms, locationArea)

				const asset: Partial<Asset> = defaultAsset({
					asset_Name: jsonFile[i].Name,
					asset_SerialNo: jsonFile[i].SerialNumber,
					asset_FacilityAssetNumber: jsonFile[i].FacilityNumber,
					asset_InstallDate: jsonFile[i].InstallDate !== '' ? jsonFile[i].InstallDate : null,
					assetTransmitter_EUI: jsonFile[i].EUI ? jsonFile[i].EUI.replace(/\s/g, '') : null, // Remove all spaces from EUI
					assetTransmitter_HardwareVersion: jsonFile[i].HardwareVersion,
					assetTransmitter_SoftwareVersion: jsonFile[i].SoftwareVersion,
					asset_LocationNote: jsonFile[i].LocationNote,
					site_Id: appState.currentSite?.site_Id,
					assetType_Id: assetType.id,
					locationBuilding_Id: locationBuilding ? locationBuilding.locationBuilding_Id : null,
					locationFloor_Id: locationFloor ? locationFloor.locationFloor_Id : null,
					locationArea_Id: locationArea ? locationArea.locationArea_Id : null,
					locationRoom_Id: locationRoom ? locationRoom.locationRoom_Id : null,
					created: defaultCreated({ create_UserId: appState.userAttributes.user_Id }),
					modified: defaultModified({ modified_UserId: appState.userAttributes.user_Id }),
					recordStatus_Id: recordStatus.Active.id,
				})
				const createdAssetResponse = await Request.post<AssetResult>(`asset`, asset, appState.authState)
				const createdAsset = createdAssetResponse.data.assets[0]

				if (createdAsset) {
					auxData.assets.push(createdAsset)
					await Promise.all([
						handleAssetTreeImport(
							appState,
							jsonFile[i].AssetsMonitored !== null ? jsonFile[i].AssetsMonitored.split(';') : [],
							auxData.assets,
							createdAsset.asset_Id,
							assetTreeStatus.Monitor.id
						),
						handleChannelMappingImport(appState, rfCompactTransmitterRowToChannelMap(jsonFile[i]), auxData.assets, createdAsset.asset_Id),
					])
				} else {
					throw createdAssetResponse.data.errors
				}
			} catch (error) {
				feedback.errors.push(`Unable to process row ${JSON.stringify(jsonFile[i])}: "${getErrorMessage(error)}"`)
			} finally {
				feedback.totalComplete = i + 1
				updateFeedback(feedback)
			}
		}
	}

	return feedback
}

export const importRfMultiTransmitter = async (importParams: ImportParams, jsonFile: Types.RfMultiTransmitterFileRow[]) => {
	const { appState, auxData, updateFeedback } = importParams
	const feedback: Types.ImportUserFeedback = { inProgress: false, totalCount: 0, totalComplete: 0, errors: [] }

	// Loop over the file generating assets and surrounding fields as we go
	if (jsonFile.length > 0) {
		feedback.inProgress = true
		feedback.totalCount = jsonFile.length
		updateFeedback(feedback)

		for (let i = 0; i < jsonFile.length; i++) {
			try {
				validateRow(jsonFile[i], appState)

				const assetType = assetTypes['RF Multi Transmitter']

				const locationBuilding = await handleLocationBuildingImport(appState, jsonFile[i].Building, auxData.locationBuildings)
				const locationFloor = await handleLocationFloorImport(appState, jsonFile[i].Floor, auxData.locationFloors, locationBuilding)
				const locationArea = await handleLocationAreaImport(appState, jsonFile[i].Area, auxData.locationAreas, locationFloor)
				const locationRoom = await handleLocationRoomImport(appState, jsonFile[i].Room, auxData.locationRooms, locationArea)

				const asset: Partial<Asset> = defaultAsset({
					asset_Name: jsonFile[i].Name,
					asset_SerialNo: jsonFile[i].SerialNumber,
					asset_FacilityAssetNumber: jsonFile[i].FacilityNumber,
					asset_InstallDate: jsonFile[i].InstallDate !== '' ? jsonFile[i].InstallDate : null,
					assetTransmitter_EUI: jsonFile[i].EUI ? jsonFile[i].EUI.replace(/\s/g, '') : null, // Remove all spaces from EUI
					assetTransmitter_HardwareVersion: jsonFile[i].HardwareVersion,
					assetTransmitter_SoftwareVersion: jsonFile[i].SoftwareVersion,
					asset_LocationNote: jsonFile[i].LocationNote,
					site_Id: appState.currentSite?.site_Id,
					assetType_Id: assetType.id,
					locationBuilding_Id: locationBuilding ? locationBuilding.locationBuilding_Id : null,
					locationFloor_Id: locationFloor ? locationFloor.locationFloor_Id : null,
					locationArea_Id: locationArea ? locationArea.locationArea_Id : null,
					locationRoom_Id: locationRoom ? locationRoom.locationRoom_Id : null,
					created: defaultCreated({ create_UserId: appState.userAttributes.user_Id }),
					modified: defaultModified({ modified_UserId: appState.userAttributes.user_Id }),
					recordStatus_Id: recordStatus.Active.id,
				})
				const createdAssetResponse = await Request.post<AssetResult>(`asset`, asset, appState.authState)
				const createdAsset = createdAssetResponse.data.assets[0]

				if (createdAsset) {
					auxData.assets.push(createdAsset)
					await Promise.all([
						handleAssetTreeImport(
							appState,
							jsonFile[i].AssetsMonitored !== null ? jsonFile[i].AssetsMonitored.split(';') : [],
							auxData.assets,
							createdAsset.asset_Id,
							assetTreeStatus.Monitor.id
						),
						handleChannelMappingImport(appState, rfMultiTransmitterRowToChannelMap(jsonFile[i]), auxData.assets, createdAsset.asset_Id),
					])
				} else {
					throw createdAssetResponse.data.errors
				}
			} catch (error) {
				feedback.errors.push(`Unable to process row ${JSON.stringify(jsonFile[i])}: "${getErrorMessage(error)}"`)
			} finally {
				feedback.totalComplete = i + 1
				updateFeedback(feedback)
			}
		}
	}

	return feedback
}

export const importGateway = async (importParams: ImportParams, jsonFile: Types.GatewayImportFileRow[]) => {
	const { appState, auxData, updateFeedback } = importParams
	const feedback: Types.ImportUserFeedback = { inProgress: false, totalCount: 0, totalComplete: 0, errors: [] }

	// Special case just for Gateways: Order the jsonFile rows by the Type, add "LORAWAN PF GATEWAY" types first
	jsonFile.sort((a, b) => {
		if (a.Type === 'LORAWAN PF GATEWAY') {
			return -1
		} else if (b.Type === 'LORAWAN PF GATEWAY') {
			return 1
		} else {
			return 0
		}
	})

	//Loop over the file generating assets and surrounding fields as we go
	if (jsonFile.length > 0) {
		feedback.inProgress = true
		feedback.totalCount = jsonFile.length
		updateFeedback(feedback)

		for (let i = 0; i < jsonFile.length; i++) {
			try {
				validateRow(jsonFile[i], appState)

				const assetType = assetTypes['Gateway']

				const locationBuilding = await handleLocationBuildingImport(appState, jsonFile[i].Building, auxData.locationBuildings)
				const locationFloor = await handleLocationFloorImport(appState, jsonFile[i].Floor, auxData.locationFloors, locationBuilding)
				const locationArea = await handleLocationAreaImport(appState, jsonFile[i].Area, auxData.locationAreas, locationFloor)
				const locationRoom = await handleLocationRoomImport(appState, jsonFile[i].Room, auxData.locationRooms, locationArea)

				const asset: Partial<Asset> = defaultAsset({
					asset_Name: jsonFile[i].Name,
					asset_SerialNo: jsonFile[i].SerialNumber,
					asset_FacilityAssetNumber: jsonFile[i].FacilityNumber,
					asset_InstallDate: jsonFile[i].InstallDate !== '' ? jsonFile[i].InstallDate : null,
					serialPort_IPAddress: jsonFile[i]['IP Address'],
					assetTransmitter_EUI: jsonFile[i].EUI ? jsonFile[i].EUI.replace(/\s/g, '') : null, // Remove all spaces from EUI
					assetTransmitter_HardwareVersion: jsonFile[i].HardwareVersion,
					assetTransmitter_SoftwareVersion: jsonFile[i].SoftwareVersion,
					asset_LocationNote: jsonFile[i].LocationNote,
					site_Id: appState.currentSite?.site_Id,
					assetType_Id: assetType.id,
					locationBuilding_Id: locationBuilding ? locationBuilding.locationBuilding_Id : null,
					locationFloor_Id: locationFloor ? locationFloor.locationFloor_Id : null,
					locationArea_Id: locationArea ? locationArea.locationArea_Id : null,
					locationRoom_Id: locationRoom ? locationRoom.locationRoom_Id : null,
					created: defaultCreated({ create_UserId: appState.userAttributes.user_Id }),
					modified: defaultModified({ modified_UserId: appState.userAttributes.user_Id }),
					recordStatus_Id: recordStatus.Active.id,
				})
				const createdAssetResponse = await Request.post<AssetResult>(`asset`, asset, appState.authState)
				const createdAsset = createdAssetResponse.data.assets[0]

				if (createdAsset) {
					auxData.assets.push(createdAsset)
					await handleAssetTreeImport(
						appState,
						jsonFile[i].AssetsMonitored !== null ? jsonFile[i].AssetsMonitored.split(';') : [],
						auxData.assets,
						createdAsset.asset_Id,
						assetTreeStatus.Monitor.id
					)
				} else {
					throw createdAssetResponse.data.errors
				}
			} catch (error) {
				feedback.errors.push(`Unable to process row ${JSON.stringify(jsonFile[i])}: "${getErrorMessage(error)}"`)
			} finally {
				feedback.totalComplete = i + 1
				updateFeedback(feedback)
			}
		}
	}

	return feedback
}

export const importSerialPort = async (importParams: ImportParams, jsonFile: Types.SerialPortImportFileRow[]) => {
	const { appState, auxData, updateFeedback } = importParams
	const feedback: Types.ImportUserFeedback = { inProgress: false, totalCount: 0, totalComplete: 0, errors: [] }

	// Loop over the file generating assets and surrounding fields as we go
	if (jsonFile.length > 0) {
		feedback.inProgress = true
		feedback.totalCount = jsonFile.length
		updateFeedback(feedback)

		for (let i = 0; i < jsonFile.length; i++) {
			try {
				validateRow(jsonFile[i], appState)

				const assetType = assetTypes['Serial Port']

				const locationBuilding = await handleLocationBuildingImport(appState, jsonFile[i].Building, auxData.locationBuildings)
				const locationFloor = await handleLocationFloorImport(appState, jsonFile[i].Floor, auxData.locationFloors, locationBuilding)
				const locationArea = await handleLocationAreaImport(appState, jsonFile[i].Area, auxData.locationAreas, locationFloor)
				const locationRoom = await handleLocationRoomImport(appState, jsonFile[i].Room, auxData.locationRooms, locationArea)

				const asset: Partial<Asset> = defaultAsset({
					asset_Name: jsonFile[i].Name,
					asset_SerialNo: jsonFile[i].SerialNumber,
					asset_FacilityAssetNumber: jsonFile[i].FacilityNumber,
					asset_InstallDate: jsonFile[i].InstallDate !== '' ? jsonFile[i].InstallDate : null,
					serialPort_IPAddress: jsonFile[i]['IP Address'],
					serialPort_COMPort: jsonFile[i]['COM Port'],
					asset_LocationNote: jsonFile[i].LocationNote,
					site_Id: appState.currentSite?.site_Id,
					assetType_Id: assetType.id,
					locationBuilding_Id: locationBuilding ? locationBuilding.locationBuilding_Id : null,
					locationFloor_Id: locationFloor ? locationFloor.locationFloor_Id : null,
					locationArea_Id: locationArea ? locationArea.locationArea_Id : null,
					locationRoom_Id: locationRoom ? locationRoom.locationRoom_Id : null,
					created: defaultCreated({ create_UserId: appState.userAttributes.user_Id }),
					modified: defaultModified({ modified_UserId: appState.userAttributes.user_Id }),
					recordStatus_Id: recordStatus.Active.id,
				})
				const createdAssetResponse = await Request.post<AssetResult>(`asset`, asset, appState.authState)
				const createdAsset = createdAssetResponse.data.assets[0]

				if (createdAsset) {
					auxData.assets.push(createdAsset)
					await handleAssetTreeImport(
						appState,
						jsonFile[i].AssetsMonitored !== null ? jsonFile[i].AssetsMonitored.split(';') : [],
						auxData.assets,
						createdAsset.asset_Id,
						assetTreeStatus.Monitor.id
					)
				} else {
					throw createdAssetResponse.data.errors
				}
			} catch (error) {
				feedback.errors.push(`Unable to process row ${JSON.stringify(jsonFile[i])}: "${getErrorMessage(error)}"`)
			} finally {
				feedback.totalComplete = i + 1
				updateFeedback(feedback)
			}
		}
	}

	return feedback
}

/* helpers for the helpers */
const handleAssetMakeImport = async (appState: AppState, assetMake_Name: string, assetMakes: AssetMake[]) => {
	if (assetMake_Name !== null) {
		const findAssetMake = assetMakes.find((a) => a.assetMake_Name === assetMake_Name)

		if (!findAssetMake) {
			const newAssetMake = defaultAssetMake({
				assetMake_Name: assetMake_Name,
				created: defaultCreated({ create_UserId: appState.userAttributes.user_Id }),
				modified: defaultModified({ modified_UserId: appState.userAttributes.user_Id }),
			})

			const createdAssetMakeResponse = await Request.post<AssetMakeResult>('assetMake', newAssetMake, appState.authState)
			const createdAssetMake = createdAssetMakeResponse.data.assetMakes[0]
			assetMakes.push(createdAssetMake)

			return createdAssetMake
		}
		return findAssetMake
	}
	return null
}

const handleAssetModelImport = async (
	appState: AppState,
	assetModel_Name: string,
	assetModels: AssetModel[],
	assetMake: AssetMake | null,
	assetType: AssetType
) => {
	if (assetModel_Name !== null && assetMake !== null) {
		const findAssetModel = assetModels.find((a) => a.assetModel_Name === assetModel_Name)

		if (!findAssetModel) {
			const newAssetModel = defaultAssetModel({
				assetModel_Name: assetModel_Name,
				assetType_Id: assetType.id,
				assetMake_Id: assetMake.assetMake_Id,
				created: defaultCreated({ create_UserId: appState.userAttributes.user_Id }),
				modified: defaultModified({ modified_UserId: appState.userAttributes.user_Id }),
			})

			const createdAssetModelResponse = await Request.post<AssetModelResult>('assetModel', newAssetModel, appState.authState)
			const createdAssetModel = createdAssetModelResponse.data.assetModels[0]
			assetModels.push(createdAssetModel)

			return createdAssetModel
		}
		return findAssetModel
	}
}

const handleLocationBuildingImport = async (appState: AppState, locationBuilding_Name: string, locationBuildings: LocationBuilding[]) => {
	if (locationBuilding_Name !== null) {
		const findLocationBuilding = locationBuildings.find((a) => a.locationBuilding_Name === locationBuilding_Name)

		if (!findLocationBuilding) {
			const newLocationBuilding = defaultLocationBuilding({
				locationBuilding_Name: locationBuilding_Name,
				site_Id: appState.currentSite?.site_Id,
				created: defaultCreated({ create_UserId: appState.userAttributes.user_Id }),
				modified: defaultModified({ modified_UserId: appState.userAttributes.user_Id }),
			})

			const createdLocationBuildingResponse = await Request.post<LocationBuildingResult>('locationBuilding', newLocationBuilding, appState.authState)
			const createdLocationBuilding = createdLocationBuildingResponse.data.locationBuildings[0]
			locationBuildings.push(createdLocationBuilding)

			return createdLocationBuilding
		}
		return findLocationBuilding
	}
	return null
}

const handleLocationFloorImport = async (
	appState: AppState,
	locationFloor_Name: string,
	locationFloors: LocationFloor[],
	locationBuilding: LocationBuilding | null
) => {
	if (locationFloor_Name !== null && locationBuilding !== null) {
		const findLocationFloor = locationFloors.find(
			(a) => a.locationFloor_Name === locationFloor_Name.toString() && a.locationBuilding_Id === locationBuilding.locationBuilding_Id
		)

		if (!findLocationFloor) {
			const newLocationFloor = defaultLocationFloor({
				locationFloor_Name: locationFloor_Name,
				locationBuilding_Id: locationBuilding.locationBuilding_Id,
				site_Id: appState.currentSite?.site_Id,
				created: defaultCreated({ create_UserId: appState.userAttributes.user_Id }),
				modified: defaultModified({ modified_UserId: appState.userAttributes.user_Id }),
			})

			const createdLocationFloorResponse = await Request.post<LocationFloorResult>('locationFloor', newLocationFloor, appState.authState)
			const createdLocationFloor = createdLocationFloorResponse.data.locationFloors[0]
			locationFloors.push(createdLocationFloor)

			return createdLocationFloor
		}
		return findLocationFloor
	}
	return null
}

const handleLocationAreaImport = async (appState: AppState, locationArea_Name: string, locationAreas: LocationArea[], locationFloor: LocationFloor | null) => {
	if (locationArea_Name !== null && locationFloor !== null) {
		const findLocationArea = locationAreas.find(
			(a) => a.locationArea_Name === locationArea_Name.toString() && a.locationFloor_Id === locationFloor.locationFloor_Id
		)

		if (!findLocationArea) {
			const newLocationArea = defaultLocationArea({
				locationArea_Name: locationArea_Name,
				locationFloor_Id: locationFloor.locationFloor_Id,
				site_Id: appState.currentSite?.site_Id,
				created: defaultCreated({ create_UserId: appState.userAttributes.user_Id }),
				modified: defaultModified({ modified_UserId: appState.userAttributes.user_Id }),
			})

			const createdLocationAreaResponse = await Request.post<LocationAreaResult>('locationArea', newLocationArea, appState.authState)
			const createdLocationArea = createdLocationAreaResponse.data.locationAreas[0]
			locationAreas.push(createdLocationArea)

			return createdLocationArea
		}
		return findLocationArea
	}
	return null
}

const handleLocationRoomImport = async (appState: AppState, locationRoom_Name: string, locationRooms: LocationRoom[], locationArea: LocationArea | null) => {
	if (locationRoom_Name !== null && locationArea !== null) {
		const findLocationRoom = locationRooms.find(
			(a) => a.locationRoom_Name === locationRoom_Name.toString() && a.locationArea_Id === locationArea.locationArea_Id
		)

		if (!findLocationRoom) {
			const newLocationRoom = defaultLocationRoom({
				locationRoom_Name: locationRoom_Name,
				locationArea_Id: locationArea.locationArea_Id,
				site_Id: appState.currentSite?.site_Id,
				created: defaultCreated({ create_UserId: appState.userAttributes.user_Id }),
				modified: defaultModified({ modified_UserId: appState.userAttributes.user_Id }),
			})

			const createdLocationRoomResponse = await Request.post<LocationRoomResult>('locationRoom', newLocationRoom, appState.authState)
			const createdLocationRoom = createdLocationRoomResponse.data.locationRooms[0]
			locationRooms.push(createdLocationRoom)

			return createdLocationRoom
		}
		return findLocationRoom
	}
	return null
}

const handlePaymentAgreementImport = async (paymentAgreement_OrderNumber: string, paymentAgreements: PaymentAgreement[]) => {
	if (paymentAgreement_OrderNumber !== null) {
		const findPaymentAgreement = paymentAgreements.find((a) => a.paymentAgreement_OrderNumber === paymentAgreement_OrderNumber.toString())

		if (!findPaymentAgreement) {
			throw `Payment Agreement ${paymentAgreement_OrderNumber} not found`
		}
		return findPaymentAgreement
	}
	return null
}

const handleAssetTreeImport = async (
	appState: AppState,
	assetChildrenNames: string[],
	assets: Asset[],
	createdAsset_Id: string,
	status_Id: AssetTreeStatusId
) => {
	// process all, collect errors and throw them back to be caught by calling function
	const assetsErrored: string[] = []
	const assetTreesErrored: string[] = []

	await Promise.all(
		assetChildrenNames.map(async (assetChildName) => {
			try {
				const findChildAsset = assets.find((a) => a.asset_Name === assetChildName)

				if (!findChildAsset) {
					assetsErrored.push(assetChildName)
					return
				}

				const newAssetTree = defaultAssetTree({
					assetTree_ParentAssetId: createdAsset_Id,
					assetTree_ChildAssetId: findChildAsset.asset_Id,
					assetTreeStatus_Id: status_Id,
					created: defaultCreated({ create_UserId: appState.userAttributes.user_Id }),
					modified: defaultModified({ modified_UserId: appState.userAttributes.user_Id }),
				})
				await Request.post<AssetTreeResult>('assetTree', newAssetTree, appState.authState)
			} catch (error) {
				assetTreesErrored.push(assetChildName)
			}
		})
	)

	let errorMessage = ''
	if (assetsErrored.length > 0) {
		errorMessage = `${status_Id === assetTreeStatus.Service.id ? 'Serviced' : 'Monitored'} Assets: ${assetsErrored.join(', ')} could not be found`
	}
	if (assetTreesErrored.length > 0) {
		errorMessage += `${errorMessage.length > 0 ? '; ' : ''}${
			status_Id === assetTreeStatus.Service.id ? 'Serviced' : 'Monitored'
		} Assets: ${assetTreesErrored.join(', ')} could not be linked to parent`
	}
	if (errorMessage !== '') {
		throw errorMessage
	}
}

const handleChannelMappingImport = async (appState: AppState, channelMapsFromFile: Types.ChannelMapFromFile[], assets: Asset[], createdAsset_Id: string) => {
	const errors: { channelMap: Types.ChannelMapFromFile; error: string }[] = []

	await Promise.all(
		channelMapsFromFile.map(async (channelMap) => {
			try {
				const findMonitoredAsset = assets.find((a) => a.asset_Name === channelMap.assetName)

				// collect errors
				if (!findMonitoredAsset) {
					errors.push({ channelMap, error: 'Asset could not be found' })
					return
				}
				if (channelMap.readingTypeId === 'ERROR') {
					errors.push({ channelMap, error: `Reading Type Value must be one of: 'COLD', 'WARM', 'HOT'` })
					return
				}

				const newChannelMap = defaultChannelMap({
					channelMap_PortNumber: channelMap.portNumber,
					channelMap_SubChannel: channelMap.subChannelNumber,
					channelMap_HubAssetId: createdAsset_Id,
					channelMap_TmvAssetId: findMonitoredAsset.asset_Id,
					readingType_Id: channelMap.readingTypeId,
					created: defaultCreated({ create_UserId: appState.userAttributes.user_Id }),
					modified: defaultModified({ modified_UserId: appState.userAttributes.user_Id }),
				})
				await Request.post<ChannelMapResult>('channelMap', newChannelMap, appState.authState)
			} catch (error) {
				errors.push({ channelMap, error: getErrorMessage(error) || 'Server error' })
			}
		})
	)

	if (errors.length > 0) {
		throw `${errors.map((map) => `${map.channelMap.fileHeader}: ${map.error}`).join('; ')}`
	}
}

const getTempReadingTypeId = (value: string) => {
	switch (value) {
		case 'COLD':
			return readingType['Temperature Reading Cold'].id
		case 'WARM':
			return readingType['Temperature Reading Mixed'].id
		case 'HOT':
			return readingType['Temperature Reading Hot'].id
		// null is ok, string value other than COLD, WARM, HOT, not ok
		case null:
			return null
		default:
			return 'ERROR'
	}
}

const getFlowReadingTypeId = (value: string) => {
	switch (value) {
		case 'COLD':
			return readingType['Flow Reading Cold'].id
		case 'WARM':
			return readingType['Flow Reading Mixed'].id
		case 'HOT':
			return readingType['Flow Reading Hot'].id
		case null:
			return null
		default:
			return 'ERROR'
	}
}

const getPressureReadingTypeId = (value: string) => {
	if (value) {
		return readingType['Pressure Reading'].id
	}
	return null
}

const sfmStandardHubRowToChannelMap = (row: Types.SfmStandardHubImportFileRow) => {
	return [
		{ fileHeader: 'P1T', portNumber: 1, assetName: row['P1 ASSET'], subChannelNumber: 1, readingTypeId: getTempReadingTypeId(row.P1T) },
		{ fileHeader: 'P2T', portNumber: 2, assetName: row['P2 ASSET'], subChannelNumber: 1, readingTypeId: getTempReadingTypeId(row.P2T) },
		{ fileHeader: 'P3T', portNumber: 3, assetName: row['P3 ASSET'], subChannelNumber: 1, readingTypeId: getTempReadingTypeId(row.P3T) },
		{ fileHeader: 'P4T', portNumber: 4, assetName: row['P4 ASSET'], subChannelNumber: 1, readingTypeId: getTempReadingTypeId(row.P4T) },
		{ fileHeader: 'P5T', portNumber: 5, assetName: row['P5 ASSET'], subChannelNumber: 1, readingTypeId: getTempReadingTypeId(row.P5T) },
		{ fileHeader: 'P6T', portNumber: 6, assetName: row['P6 ASSET'], subChannelNumber: 1, readingTypeId: getTempReadingTypeId(row.P6T) },
		{ fileHeader: 'P7T', portNumber: 7, assetName: row['P7 ASSET'], subChannelNumber: 1, readingTypeId: getTempReadingTypeId(row.P7T) },
		{ fileHeader: 'P8T', portNumber: 8, assetName: row['P8 ASSET'], subChannelNumber: 1, readingTypeId: getTempReadingTypeId(row.P8T) },
		// we can ignore null values as values aren't mandatory, but we want to keep errors for reporting
	].filter((channelMap) => channelMap.readingTypeId !== null) as Types.ChannelMapFromFile[]
}

const sfmTfpHubRowToChannelMap = (row: Types.SfmTfpHubImportFileRow) => {
	// 5 standard ports * 6 sub channels per port
	const standardPortChannels = Array.from({ length: 30 }).map((v, i) => {
		const sectionNumber = Math.floor(i / 6)
		const portNumber = sectionNumber + 1
		const channelNumber = Math.floor((i % 6) / 2)
		const readingType = i % 2 === 0 ? 'F' : 'T'
		const fileHeader = `P${portNumber}CH${channelNumber}${readingType}`
		const getReadingTypeFn = readingType === 'F' ? getFlowReadingTypeId : getTempReadingTypeId

		return {
			fileHeader: fileHeader,
			portNumber: portNumber,
			assetName: row[`P${portNumber} ASSET` as keyof Types.SfmTfpHubImportFileRow] as string,
			subChannelNumber: channelNumber + 1,
			readingTypeId: getReadingTypeFn(row[fileHeader as keyof Types.SfmTfpHubImportFileRow] as string),
		}
	})

	const portSixChannels = [
		{ fileHeader: 'P6CH0P', portNumber: 6, assetName: row['P6 ASSET'], subChannelNumber: 1, readingTypeId: getPressureReadingTypeId(row.P6CH0P) },
		{ fileHeader: 'P6CH1P', portNumber: 6, assetName: row['P6 ASSET'], subChannelNumber: 2, readingTypeId: getPressureReadingTypeId(row.P6CH1P) },
	]

	return standardPortChannels.concat(portSixChannels).filter((channelMap) => channelMap.readingTypeId !== null) as Types.ChannelMapFromFile[]
}

const rfCompactTransmitterRowToChannelMap = (row: Types.RfCompactTransmitterFileRow) => {
	return [
		{ fileHeader: 'CH0F', portNumber: 1, assetName: row['P1 ASSET'], subChannelNumber: 1, readingTypeId: getFlowReadingTypeId(row.CH0F) },
		{ fileHeader: 'CH0T', portNumber: 1, assetName: row['P1 ASSET'], subChannelNumber: 1, readingTypeId: getTempReadingTypeId(row.CH0T) },
	].filter((channelMap) => channelMap.readingTypeId !== null) as Types.ChannelMapFromFile[]
}

const rfMultiTransmitterRowToChannelMap = (row: Types.RfMultiTransmitterFileRow) => {
	return [
		{ fileHeader: 'CH0F', portNumber: 1, assetName: row['P1 ASSET'], subChannelNumber: 1, readingTypeId: getFlowReadingTypeId(row.CH0F) },
		{ fileHeader: 'CH0T', portNumber: 1, assetName: row['P1 ASSET'], subChannelNumber: 1, readingTypeId: getTempReadingTypeId(row.CH0T) },
		{ fileHeader: 'CH1F', portNumber: 1, assetName: row['P1 ASSET'], subChannelNumber: 2, readingTypeId: getFlowReadingTypeId(row.CH1F) },
		{ fileHeader: 'CH1T', portNumber: 1, assetName: row['P1 ASSET'], subChannelNumber: 2, readingTypeId: getTempReadingTypeId(row.CH1T) },
		{ fileHeader: 'CH2F', portNumber: 1, assetName: row['P1 ASSET'], subChannelNumber: 3, readingTypeId: getFlowReadingTypeId(row.CH2F) },
		{ fileHeader: 'CH2T', portNumber: 1, assetName: row['P1 ASSET'], subChannelNumber: 3, readingTypeId: getTempReadingTypeId(row.CH2T) },
	].filter((channelMap) => channelMap.readingTypeId !== null) as Types.ChannelMapFromFile[]
}

// Trim white space and check dates
const validateRow = <T extends Types.ImportFileRow>(fileRow: T, appState: AppState) => {
	for (const key in fileRow) {
		const typedKey = key as keyof T
		if (fileRow[typedKey] !== null && typeof fileRow[typedKey] === 'string') {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			fileRow[typedKey] = (fileRow[typedKey] as string).trim() as any
		}
	}

	if (fileRow.Type === null) {
		throw `Type is required`
	}

	if (fileRow.InstallDate && !dateIsValidFormat(fileRow.InstallDate)) {
		throw `Date '${fileRow.InstallDate}' is not in correct format, please format dates as 'yyyy-MM-dd', e.g. '2030-01-01'`
	} else if (fileRow.InstallDate) {
		fileRow.InstallDate =
			formatOutgoingDateTime({ dateTime: fileRow.InstallDate, format: 'DateString', timeZone: appState.currentSite?.site_Timezone }) ||
			fileRow.InstallDate
	}
}

const dateIsValidFormat = (date: string): boolean => {
	const regEx = /^\d{4}-\d{2}-\d{2}$/
	return date.match(regEx) != null
}

const getErrorMessage = (error: unknown) => {
	if (typeof error === 'string') {
		return error
	}
	const msError = error as AxiosError<MSExceptionResult>
	if (msError.response?.data?.ExceptionMessage) {
		return msError.response.data.ExceptionMessage
	}
	return 'Something went wrong'
}
