import React, { useContext } from 'react'
import { Formik } from 'formik'
import { Col, Modal, Row } from 'react-bootstrap'
import { Button } from '../../Button/Button'
import { AssetTree, addAssetTreeFilterAssetOptions, defaultAssetTree } from '../../../../models/AssetTree'
import { defaultCreated, defaultModified } from '../../../../models/History'
import { AppContext } from '../../../../App'
import { Asset, AssetList, AssetListResult } from '../../../../models/Asset'
import { PageStatus } from '../../../../types/PageStatus'
import * as Request from '../../../../utilities/request'
import { Loading } from '../../Loading/Loading'
import { FormSelect, SelectOption } from '../../Form/Select'
import { AssetTreeStatus, assetTreeStatus } from '../../../../constants/assetTreeStatus'

interface AssetTreeEditModalProps {
	show: boolean
	setShow: React.Dispatch<React.SetStateAction<boolean>>
	handleSubmit: (assetTree: AssetTree) => Promise<void>
	asset: Asset
	type: 'Serviced By' | 'Monitored By' | 'Serviced' | 'Monitored'
}

const AssetTreeEditModal = (props: AssetTreeEditModalProps) => {
	const context = useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const defaultFormValues = defaultAssetTree({
		created: defaultCreated({ create_UserId: context.appState.userAttributes.user_Id }),
		modified: defaultModified({ modified_UserId: context.appState.userAttributes.user_Id }),
	})
	const [assetTree, setAssetTree] = React.useState<AssetTree>(defaultFormValues)

	const [assets, setAssets] = React.useState<AssetList[]>([])
	const filteredAssets = React.useMemo(() => {
		return assets.length > 0 ? addAssetTreeFilterAssetOptions(props.type, props.asset, assets) : [] // Checking the length prevents rendering before the assets are loaded
	}, [assets, props.type, props.asset.asset_Serviced])
	const assetOptions = React.useMemo(() => {
		return filteredAssets.map((a) => ({ label: a.asset_Name ? a.asset_Name : '', value: a.asset_Id })) // asset_Name is guaranteed to be defined, this is to keep typescript happy
	}, [filteredAssets])

	React.useEffect(() => {
		const getData = async () => {
			const [assetResponse] = await Promise.all([
				Request.get<AssetListResult>(`asset?site_Id=${context.appState.currentSite?.site_Id}`, context.appState.authState),
			])

			setAssets(assetResponse.data.assets)
			setPageStatus('Ready')
		}

		if (context.appState.authState.isLoggedIn && props.show && assets.length == 0) {
			setPageStatus('Loading')
			getData()
		}
	}, [context, props.show])

	// Handles the business logic around new asset tree values when we select an asset to add
	const setAssetTreeValues = (asset_Id: string, values: AssetTree) => {
		const selectedAsset = assets.find((a) => a.asset_Id === asset_Id)
		if (selectedAsset) {
			switch (props.type) {
				case 'Serviced By':
					return {
						...values,
						asset: selectedAsset,
						assetTree_ParentAssetId: selectedAsset.asset_Id,
						assetTree_ChildAssetId: props.asset.asset_Id,
						assetTreeStatus_Id: assetTreeStatus.Service.id,
						assetTreeStatus_Name: 'Service' as AssetTreeStatus,
					}
				case 'Monitored By':
					return {
						...values,
						asset: selectedAsset,
						assetTree_ParentAssetId: selectedAsset.asset_Id,
						assetTree_ChildAssetId: props.asset.asset_Id,
						assetTreeStatus_Id: assetTreeStatus.Monitor.id,
						assetTreeStatus_Name: 'Monitor' as AssetTreeStatus,
					}
				case 'Serviced':
					return {
						...values,
						asset: selectedAsset,
						assetTree_ParentAssetId: props.asset.asset_Id,
						assetTree_ChildAssetId: selectedAsset.asset_Id,
						assetTreeStatus_Id: assetTreeStatus.Service.id,
						assetTreeStatus_Name: 'Service' as AssetTreeStatus,
					}
				case 'Monitored':
					return {
						...values,
						asset: selectedAsset,
						assetTree_ParentAssetId: props.asset.asset_Id,
						assetTree_ChildAssetId: selectedAsset.asset_Id,
						assetTreeStatus_Id: assetTreeStatus.Monitor.id,
						assetTreeStatus_Name: 'Monitor' as AssetTreeStatus,
					}
			}
		}
		return values
	}

	return (
		<Formik initialValues={assetTree} onSubmit={props.handleSubmit} enableReinitialize>
			{({ handleSubmit, isSubmitting, values, setValues, handleReset }) => (
				<Modal
					show={props.show}
					onHide={() => {
						if (!isSubmitting) {
							handleReset()
							props.setShow(false)
						}
					}}
				>
					<Modal.Header closeButton>
						<Modal.Title>Add New {props.type} Asset</Modal.Title>
					</Modal.Header>

					{pageStatus === 'Loading' ? (
						<Row style={styles.loadingRow}>
							<Col />
							<Col sm="auto">
								<Loading show={true} />
							</Col>
							<Col />
						</Row>
					) : (
						<Modal.Body>
							<FormSelect
								name={'assetTree_Asset'}
								label={'Asset'}
								options={assetOptions}
								value={values.asset.asset_Id}
								onChange={(e) => {
									const assetTree = setAssetTreeValues((e as SelectOption<string>).value, values)
									setValues(assetTree)
								}}
								isClearable
								isDisabled={pageStatus === 'Submitting'}
							/>
						</Modal.Body>
					)}

					<Modal.Footer>
						<Button
							disabled={isSubmitting}
							onClick={() => {
								handleReset()
								setAssetTree(defaultFormValues)
								props.setShow(false)
							}}
						>
							Cancel
						</Button>
						<Button
							disabled={isSubmitting || !values.asset.asset_Id}
							onClick={() => {
								handleSubmit()
								setAssetTree(defaultFormValues)
								props.setShow(false)
							}}
						>
							Save
						</Button>
					</Modal.Footer>
				</Modal>
			)}
		</Formik>
	)
}

const styles: { [key: string]: React.CSSProperties } = {
	loadingRow: {
		margin: '20px',
	},
}

export { AssetTreeEditModal }
