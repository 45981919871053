import React from 'react'
import { useNavigate } from 'react-router-dom'
import '../UserInfo/UserInfo.css'

import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap'

import settings from '../../../images/icons/settings-white.svg'
import PermissionsCheck from '../../Permissions/PermissionsCheck'
import { PermissionModelObject, PermissionModelAction, PermissionModelContext } from '../../../utilities/permissions/permission.d'

const SettingsMenu = () => {
	const navigate = useNavigate()

	return (
		<OverlayTrigger placement="left" overlay={<Tooltip id={`toolTip_settings`}>Settings</Tooltip>}>
			<Dropdown className="dropdown-userinfo-header">
				<Dropdown.Toggle className="dropdown-userinfo shadow-none">
					<img src={settings} alt={'Settings Icon'} />
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<Dropdown.Item className="dropdown-userinfo-pointer" onClick={() => navigate('/userManagement')}>
						User Management
					</Dropdown.Item>
					<Dropdown.Item className="dropdown-userinfo-pointer" onClick={() => navigate('/siteImport')}>
						Import Site Configuration
					</Dropdown.Item>
					<Dropdown.Item className="dropdown-userinfo-pointer" onClick={() => navigate('/reportArchive')}>
						Report Archive
					</Dropdown.Item>
					{/* TODO: Permissions to view this */}

					<PermissionsCheck object={PermissionModelObject.Contract} action={PermissionModelAction.GET} context={PermissionModelContext.None}>
						<Dropdown.Item className="dropdown-userinfo-pointer" onClick={() => navigate('/contracts')}>
							Contract Management
						</Dropdown.Item>
					</PermissionsCheck>
				</Dropdown.Menu>
			</Dropdown>
		</OverlayTrigger>
	)
}

export { SettingsMenu }
